import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


const Bedroom = () => (
    <Layout>
    <SEO title="Wood Work" keyword={['Woodwork in hindi','Wood Selection','Lakdi ka chunao']} />
  
  <h1>Coming soon</h1>
        
  </Layout>
    )
  
    
  export default Bedroom
